
import { defineComponent } from "vue";
import {
  IonPage,
  IonContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  toastController,
} from "@ionic/vue";
import { lockClosed } from "ionicons/icons";
import axios from "axios";

export default defineComponent({
  name: "ForgotPw",
  data() {
    return {
      email: null,
      loading: false,
    };
  },
  setup() {
    return { lockClosed };
  },
  methods: {
    async forgotPasswordRequest() {
      this.loading = true;
      const formdata = new FormData();
      formdata.append("email", this.email);

      await axios
        .post("forgot-password", formdata)
        .then(async ({ data }) => {
          this.$router.back()
          this.loading = false;
          const toast = await toastController.create({
            message: data.message,
            duration: 2000,
          });
          return toast.present();
        })
        .catch(async ({ response }) => {
          this.loading = false;
          const toast = await toastController.create({
            message: response.data.message,
            duration: 2000,
          });
          return toast.present();
        });
    },
  },

  components: { IonPage, IonContent, IonItem, IonLabel, IonInput, IonButton },
});
